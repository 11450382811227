@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";



/* Prevent horizontal overflow globally - so the annoying horizontal swipe to empty space is gone */

/* Specific sections or containers that may overflow */
/*.custom-hero-bg, .section-container, .main-container {
  overflow-x: hidden; /* Ensure each section does not allow overflow */
/*} -- ^^^^^^ THIS IS COMMENTED OUT AS THE HORIZONTAL SWIPE IS GONE AND NAVBAR STICKS WHEN THIS IS REMOVED .

/* Ensure the body or any large container doesn't exceed the viewport width */
body {
  margin: 0;
  padding: 0;
  width: 100vw; /* Ensure it does not extend beyond the viewport width */
  box-sizing: border-box;
}

.hero-section, .section-container {
  max-width: 100%; /* Prevent sections from exceeding 100% of the viewport */
}


html {
    scroll-behavior: smooth;
  }
    

/* for the raiyan abedin logo on top left - (currently moved on, will come back and fix soon?) */
#logo-name {
    opacity: 0;
    transition: opacity 0.3s ease; /* Smooth transition */
}

/* for the raiyan "y" section on the hero section - allows the letter y not to get cut off */
.line-height-fix {
    line-height: 1.2; /* Increase the line height slightly */
  }



/* Ensure the anchor tag has enough padding for a larger hitbox */
.icon-link {
    padding: 15px; /* Increases the hitbox size */
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Add some hover effects to visually indicate the link is clickable */
  .icon-link:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Optional: add a subtle hover effect */
    border-radius: 50%; /* Makes the hitbox appear circular if desired */
  }

  .icon-container {
    position: relative; /* Ensure it's positioned within its container */
    z-index: 10; /* Bring the icons to the front */
  }
  


/* for the font */
@font-face {
    font-family: 'YourCustomFont';
    src: url('') format('truetype'); /* Adjust format as per your font file type */
    font-weight: normal;
    font-style: normal;
  }
  
/* Project Section Title */
#projects h2 {
    font-family: 'YourCustomFont', sans-serif; /* Custom font for Projects section title */
  }
  
  /* Professional Experience Section Title */
  #technologies-education h2 {
    font-family: 'YourCustomFont', sans-serif; /* Custom font for Experience section title */
  }
  
  #education-section h2 {
    font-family: 'YourCustomFont', sans-serif; /* Custom font for Education section title */
  }


  /* Contact Me Section Title */
  #contact h2 {
    font-family: 'YourCustomFont', sans-serif; /* Custom font for Contact Me section title */
  }
  


/* the background gradient animation */
.custom-hero-bg {
    background: linear-gradient(135deg, #0B0312, #3A0B5C, #0B0312, #56127E);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
  }
  
  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  

/* the gradient text for my name Raiyan  */
.gradient-text {
    background: linear-gradient(45deg, #8728D8, #FF00FF);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: gradientAnimation 3s ease infinite;
  }
  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }




/* Add this CSS */
.about-section {
  display: flex;
  flex-direction: column; /* Stack the image and text vertically */
  align-items: center; /* Horizontally center the text and image */
  text-align: center; /* Center the text inside the section */
  justify-content: center; /* Vertically center the content */
}








/* Adjusted Project card styles - for PROJECT SECTION*/
.project-card {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    0 0 20px #43156c, /* Neon purple glow */
                0 0 40px #43156c,
                0 0 60px #8728D8;
    transition: transform 0.3s, box-shadow 0.3s;
    width: 100%; /* Full width */
    max-width: 600px; /* Slightly smaller max width */
    margin: 0 auto; /* Center horizontally */
    position: relative;
    padding: 15px; /* Slightly smaller padding */
}

.project-image {
    width: 100%;
    height: 210px; /* Smaller height for images */
    border-radius: 10px 10px 0 0; /* Rounded top corners */
    object-fit: cover;
    transition: transform 0.3s ease;
}

.project-card:hover {
    transform: scale(1.05); /* Slight zoom on hover */
    box-shadow: 0 10px 15px rgba(135, 40, 216, 0.5), /* Original hover shadow, can change for dimmer/brighter look */
                0 0 30px #8728D8, /* Brighter neon purple glow */
                0 0 60px #8728D8,
                0 0 80px #8728D8;
}

.project-content {
    padding: 10px; /* Slightly smaller padding */
    background-color: rgb(255, 255, 255); /* White background for content */
    color: #000000; /* Black text color for contrast */
    border-radius: 0 0 10px 10px; /* Match the rounded corners */
}

.project-title {
    font-size: 1.4rem; /* Slightly smaller title */
    font-weight: bold;
    color: #8728D8;
    margin-bottom: 10px;
}

.project-description {
    font-size: 1rem;
    color: #000000;
}

/* Adjust grid for project cards */
.grid {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; /* Slightly reduced space between cards */
    justify-content: center;
    align-items: center;
}


/* Background for the entire section - PROJECT SECTION - Dark Purple Theme */
.custom-project-bg {
    background: linear-gradient(to right, #0B0312, #3A0B5C, #0B0312);
    padding: 2rem 0;
}


/* CSS for NavBar Animations */
.nav-item {
    color: white;
    padding: 10px 15px;
    transition: color 0.3s ease, transform 0.3s ease;
}

/* Base Navbar Styles */
.navbar {
    position: sticky; /* Makes the navbar stick to the top when scrolling */
    top: 0; /* Ensures it stays at the top */
    z-index: 1000; /* Ensures it stays on top of other content */
    background-color: rgba(0, 0, 0, 0); /* Make it transparent */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    padding: 10px 0; /* Padding for the navbar */
    display: flex;
    justify-content: space-between; /* Align items to the left and right */
    align-items: center;
    width: 100%; /* Full width */
    border: none; /* Remove any border */
    box-shadow: none; /* Remove any box-shadow */
    
}


/* Scrolled Navbar Styles */
.navbar.scrolled {
    background-color: #0B0312; /* Darker background when scrolled */
    border: none; /* Remove any border */
    box-shadow: none; /* Remove any box-shadow */
}

/* Navbar Item Styles */
.nav-item {
    color: white;
    padding: 10px 15px;
    transition: color 0.3s ease, transform 0.3s ease;
    text-decoration: none; /* Remove underline from links */
}

.nav-item:hover {
    color: #8728D8; /* Highlight color on hover */
    transform: scale(1.1); /* Slightly increase size on hover */
}

/* Responsive Navbar Button Styles */
.nav-button {
    padding: 10px 20px;
    color: white;
    border: 2px solid #8728D8;
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    border-radius: 5px;
}

.nav-button:hover {
    background-color: #8728D8;
    color: white;
    transform: scale(1.05);
}









/* Contact Form Container */
.contact-form {
    background: linear-gradient(135deg, #3A0B5C, #56127E, #6D28D9);
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3); /* Soft shadow */
    margin: 0 auto; /* Center horizontally */
    max-width: 700px; /* Limit max width */
    color: #fff; /* Text color */
}

/* Contact Input Styles */
.contact-input {
    background: rgba(255, 255, 255, 0.1); /* Transparent input background */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Light border */
    color: white;
    padding: 10px;
    border-radius: 8px;
    width: 100%; /* Full width */
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(135, 40, 216, 0.6); /* Purple glow */
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

/* Input focus effect with glow */
.contact-input:focus {
    box-shadow: 0 0 15px rgba(255, 0, 255, 0.8), 0 0 25px rgba(135, 40, 216, 0.9); /* Glow effect on focus */
    outline: none;
    transform: scale(1.03); /* Slight zoom */
}

/* Contact Button Styles */
.contact-button {
    background: linear-gradient(45deg, #8728D8, #FF00FF); /* Gradient button */
    padding: 12px 25px;
    border: none;
    border-radius: 30px;
    color: white;
    cursor: pointer;
    transition: background 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 0 15px rgba(135, 40, 216, 0.7); /* Initial purple glow */
}

/* Button hover effect with glow */
.contact-button:hover {
    background: linear-gradient(45deg, #FF00FF, #8728D8); /* Hover gradient */
    box-shadow: 0 0 30px rgba(135, 40, 216, 1), 0 0 50px rgba(255, 0, 255, 0.9); /* Stronger glow on hover */
    transform: scale(1.05); /* Slight zoom on hover */
}

/* Section Styles */
.contact-section {
    background: linear-gradient(to right, #0B0312, #3A0B5C, #0B0312); /* Purple gradient background */
    padding: 3rem 0;
    text-align: center;
}


/* Technologies Button Styling */

/* Badge Container */
.tech-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between badges */
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }
  
  /* Individual Badge Styles */
  .badge {
    background-image: linear-gradient(45deg, #6a0dad, #8728d8); /* Primary purple gradient */
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: bold;
    transition: transform 0.2s, box-shadow 0.2s;
    text-transform: uppercase;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Hover effect for badges */
  .badge:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3);
  }
  
  /* Gradient for specific tech with a more purple theme */
  .badge-html {
    background-image: linear-gradient(45deg, #a64ca6, #b19cd9); /* Light Purple Gradient */
  }
  
  .badge-css {
    background-image: linear-gradient(45deg, #6e44ff, #9f5fdd); /* Purple-Blue Gradient */
  }
  
  .badge-js {
    background-image: linear-gradient(45deg, #9b5de5, #f15bb5); /* Purple-Pink Gradient */
    color: #fff; /* Keep white text */
  }
  
  .badge-tailwind {
    background-image: linear-gradient(45deg, #7f5af0, #b066d4); /* Soft Purple Gradient */
  }
  
  /* Additional badge colors with a unified theme */
  .badge-python {
    background-image: linear-gradient(45deg, #875fcc, #9f88e1); /* Medium Purple Gradient */
  }
  
  .badge-API {
    background-image: linear-gradient(45deg, #5d3a9b, #7e57c2); /* Darker Purple Gradient */
  }
  
  .badge-postgres {
    background-image: linear-gradient(45deg, #845ec2, #d65db1); /* Purple-Pink Gradient */
  }
  



/*floating orb animation on hero section*/

.floating-orb {
    animation: float 10s ease-in-out infinite, fade 4s ease-in-out infinite alternate;
    z-index: -1;
  }


  

  @keyframes float {
    0% {
      transform: translateY(0) translateX(0);
    }
    50% {
      transform: translateY(-20px) translateX(20px);
    }
    100% {
      transform: translateY(0) translateX(0);
    }
  }
  
  @keyframes fade {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }


  

  

/* CSS STYLING RESPONSIVENESS FOR SMALLER SCREENS */

/* Media Query for screens smaller than 640px (i.e., iPhone) */
/* Default styles for larger screens */
.hero-heading {
  font-size: 7rem; /* Large size for desktop/laptops */
}

.hero-subheading {
  font-size: 4rem; /* Default subheading size for desktop/laptops */
}

/* Media Query for screens smaller than 640px (e.g., iPhone-like devices) */
@media (max-width: 640px) {
  .hero-heading {
    font-size: 3rem; /* Slightly reduce heading size */
  }

  .hero-subheading {
    font-size: 1.5rem; /* Reduce subheading size */
  }

  .icon-container i {
    font-size: 3rem; /* Adjust icon size for smaller screens */
  }
}

/* Media Query for screens smaller than 430px (e.g., very small screens) */
@media (max-width: 500px) {
  .hero-heading {
    font-size: 2.rem; /* Further reduce heading size */
  }

  .hero-subheading {
    font-size: 1.8rem; /* Further reduce subheading size */
  }

  .icon-container i {
    font-size: 2.5rem; /* Further reduce icon size */
  }
}
